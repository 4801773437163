.error-backdrop {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: grayscale(1);
}

.error-backdrop::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../public/images/karte.webp");
    background-position: center center;
    background-size: 110%;
    filter: opacity(0.9) blur(0.2rem);
}

.error-container {
    display: flex;
    background-color: rgba(255, 255, 255, 0.65);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.5);
    padding: 10px 23px;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    max-width: 600px;
}

.error-header {
    margin: 0 20px 0 0;
    padding: 1.3rem 23px 1rem 0;
    font-size: 24px;
    line-height: 16px;
    font-weight: 500;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    align-self: center;
}

.error-header small {
    font-size: 0.7rem;
    color: #868484;
}

.error-message {
    align-self: center;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
